import React from 'react'
import '../styles/Hero.css'
import { Link, useStaticQuery, graphql } from 'gatsby'
import 'react-slideshow-image/dist/styles.css'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import FloatingWhatsApp from 'react-floating-whatsapp'
import logoRamsko from '../images/logo4.jpg'

// import bgHero from '../images/bg.png'
import slider1 from '../images/sliderImage1.jpeg'
import slider2 from '../images/sliderImage2.jpeg'
import slider3 from '../images/sliderImage3.jpeg'
import slider4 from '../images/sliderImage4.jpeg'
import slider5 from '../images/sliderImage5.jpeg'




const Hero = () => {
  
    const data = useStaticQuery(graphql`
    query {
      allContentfulProducts {
        edges {
          node {
            productName
            productDetails {
              productDetails
            }
            productImage {
              file {
                url
              }
            }
            productSpecifications {
              file {
                url
              }
            }
            slug
          }
        }
      }
    }
  `)
    
    
  return (
    
    <div>
        <div className='bgHero w-full flex justify-center items-start'>
            <div className='bgGrey w-full'></div>
        
            <div  className='absolute'>
                <div className='flex justify-center'>
                
                <Carousel autoPlay infiniteLoop>
                <div>
                    <img className='' src={slider1} alt = 'more slider images' />
                    <h1 className='itemNameTitleHero text-center font-Almarai'>PVC<br/> رول تغليف حراري</h1>
                </div>
                <div>
                    <img className='' src={slider2} alt = 'more slider images' />
                    <h1 className='itemNameTitleHero text-center font-Almarai'>GT-900<br/> ماكنة لحام سير مستمرموديل</h1>
                </div>
                <div>
                    <img className='' src={slider3} alt = 'more slider images' />
                    <h1 className='itemNameTitleHero text-center font-Almarai'>DZQ-260<br/> ماكنة فاكيوم صحن واحد وسط موديل</h1>
                </div>
                <div>
                    <img className='' src={slider4} alt = 'more slider images' />
                    <h1 className='itemNameTitleHero text-center font-Almarai'>GT-2040<br/> ماكنة تغليف حراري سير مستمر موديل</h1>
                </div>
                <div>
                    <img className='' src={slider5} alt = 'more slider images' />
                    <h1 className='itemNameTitleHero text-center font-Almarai'>GT-PFS400-300-200<br/> مكائن لحام وقص يدويه</h1>
                </div>
            </Carousel>
                  
                </div>
            <div>
          </div>
            </div>

            
        </div>

        <div className='flex justify-center' id='ProductsHero'>
            <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:gap-6 lg:gap-5 md:gap-6 sm:gap-5 gap-4 xl:max-w-6xl 2xl:max-w-7xl lg:max-w-5xl md:max-w-3xl mx-auto px-4 sm:px-6'>
                
                {/* {data.allContentfulProducts.edges.filter((item,index)=> index<=7).map((edge) =>{ */}
                {data.allContentfulProducts.edges.map((edge) =>{
                  return (
                    <Link to={`/Products/${edge.node.slug}`}><div className='productRectangleProducts bg-white'>
                      <div className='flex justify-center'><img className='productImageProducts' src={edge.node.productImage.file.url} alt='pro' /></div>
                      <div className='flex justify-center'><h1 className = "productNameProducts text-center pt-3 font-Almarai">{edge.node.productName}</h1></div>
                    </div></Link>
                  )
                })}
                
            </div>
        </div>

        <div className='flex justify-center py-16'>
            {/* <Link to="/Products/" className= "first-letter: text-sm font-Almarai lg:text-base font-bold text-white py-2 px-8 text-center bg-primary hover:font-bold rounded-xl  underline-offset-2 decoration-2">
            مشاهدة الكل 
            </Link> */}
        </div>

        <div className='flex justify-center pb-24'>
            <h1 className='lastTextHero xl:max-w-6xl 2xl:max-w-7xl lg:max-w-5xl md:max-w-3xl mx-auto px-4 sm:px-6 font-Almarai'>
            نحن مؤسسة راماسكو لانظمة تقنيةالتغليف . متخصصون في استيراد وبيع المكائن الصناعيه والتغليف واكياس النايلو ال بي في سي  , واكياس التغليف  بي يو اف  واكياس تغليف العطور او بي بي  كما نقوم بتفصيل الاكياس علي حسب المقاسات وحسب الطلب .. يوجد لدينا مكائن الشرنك الحراري بجميع مقاساته ومكائن الاندكشن (تلحيم القصدير علي البرطمانات والعلب البلاستيكيه)ولدينا مكائن القص الحراري بجميع مقاساته ومكائن تعبية السوائل والعطور والكريمات والمواد اللزجه ولدينا قطع الغيار والصيانه .
            </h1>
        </div>

        <FloatingWhatsApp className='text-black'
          phoneNumber = "+966551161149"
          accountName = "راماسكو"
          statusMessage = "كتابة ..."
          chatMessage = "كيف يمكننا مساعدتك؟"
          avatar = {logoRamsko}
          allowClickAway
          allowEsc
          notificationSound = "true"
         />
    </div>
  )
}

export default Hero
