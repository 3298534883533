import React from 'react'
import '../styles/global.css'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import {Helmet} from 'react-helmet' 
import favicon from '../images/favicon.ico'

const Index = () => {
  return (
    <div>
      <Layout>
      <Helmet title="Ramasko | راماسكو" href={favicon}
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                      name:'icon',
                      href:'/src/images/favicon.ico',
                    },
                    {
                        name:'description',
                        content:'Ramasko',
                    }
                ]}
        />
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        <Hero/>
      </Layout>
    </div>
  )
}

export default Index
